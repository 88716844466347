import { error } from "console";
import { IUserDetailsResponse } from "../@common/interfaces/auth.interfaces";
import {
  ICheckoutResponse,
  ICustomerPortalResponse,
} from "../@common/interfaces/checkout.interfaces";
import history from "../@history";
import { authService } from "./auth.service";

export class ApiService {
  login = async (email: string, password: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      if (!response.ok) {
        throw new Error("Invalid credentials");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Login failed");
    }
  };

  register = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ firstName, lastName, email, password }),
        }
      );

      return response;
    } catch (error) {
      throw new Error("Registratation failed");
    }
  };

  forgotPassword = async (email: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      return response;
    } catch (error) {
      throw new Error("Fail to reset the password");
    }
  };

  forgotPasswordUpdate = async (
    email: string,
    newPassword: string,
    code: string
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/forgot-password-update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, newPassword, code }),
        }
      );

      return response;
    } catch (error) {
      throw new Error("Fail to reset the password");
    }
  };

  authMe = async (token: string): Promise<IUserDetailsResponse | null> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/me`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Add the Authorization header
        },
      });
      if (!response.ok) {
        return null;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  };

  checkout = async (priceId: string): Promise<ICheckoutResponse | null> => {
    const token = authService.getSession();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add the Authorization header
          },
          body: JSON.stringify({ priceId }),
        }
      );
      if (!response.ok) {
        console.log("Error fetching checkout link");
        return null;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error fetching checkout link");
    }
  };

  customerPortal = async (): Promise<ICustomerPortalResponse | null> => {
    const token = authService.getSession();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/payment/customerportal`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add the Authorization header
          },
        }
      );
      if (!response.ok) {
        console.log("Error fetching customer portal link");
        return null;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error fetching customer portal link");
    }
  };
}

export const apiService = new ApiService();
