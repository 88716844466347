import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material'; // Assuming you're using Material UI

const useStyles = {
  container: {
    padding: '2rem',
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: '#f5f5f5',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: '#333',
  },
  section: {
    marginBottom: '1.5rem',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#555',
    marginBottom: '0.5rem',
  },
  text: {
    fontSize: '1rem',
    color: '#666',
    lineHeight: '1.6',
  },
};

const PrivacyPage: React.FC = () => {
  return (
    <div id="main_content">
                

                <section >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div >
                                    <div >
                                        <br></br> <br></br> <br></br>
                                        <h2 className="title">Privacy Policy</h2>
                                        <p></p>
                                        
    <p>Welcome to <strong>myai.cx</strong> (the "Site"). We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
    <br></br>
    <h3>1. Information We Collect</h3>
   

    <h4>1.1. Personal Information</h4>
    <ul>
        <li><strong>Contact Information:</strong> Your name and your email address would be collected and stored in our backend. Your email will be used for any future communication with regards to your subscription.</li>
    </ul>

    <h4>1.2. Usage Data</h4>
    <ul>
        <li><strong>Log Information:</strong> Log in time would be recorded when your sign in to the Site.</li>
    </ul>

    <h4>1.3. Communications</h4>
    <p>You can contact our customer support via <a href="mailto:support@myai.cx">support@myai.cx</a>.</p>
    

    <h4>2. How We Use Your Information</h4>
    <p>We will only use the information that you share with us to manage your account and to communicate with you. We will not store any data that you share with us to be processed by the AI engine.</p>
   

    <h4>3. Sharing Your Information</h4>
    <p>We <u><b>strictly</b></u> dont share your infromation with 3rd parties. We ensure that your information is only stored in the backend. </p>
    

    <h4>4. Data Security</h4>
    <p>We use appropriate security measures to protect your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure. We strive to use commercially acceptable means to protect your information, but we cannot guarantee its absolute security.</p>

    <h4>5. Your Rights</h4>
    <p>You have the following rights regarding your personal information:</p>
    <ul>
        <li><strong>Access:</strong> You have the right to access the personal data we hold about you.</li>
        <li><strong>Correction:</strong> You can request that we correct any inaccuracies in your personal data.</li>
        <li><strong>Deletion:</strong> You may request the deletion of your personal data, subject to legal obligations.</li>
        <li><strong>Objection:</strong> You can object to the processing of your data in certain situations.</li>
    </ul>

   
    <h4>6. Changes to This Privacy Policy</h4>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy. Your continued use of the Site after such changes constitutes your acceptance of the new Privacy Policy.</p>

    <h4>7. Contact Us</h4>
    <p>If you have any questions about this Privacy Policy, please contact us via our email address <a href="mailto:privacy@myai.cx">privacy@myai.cx</a></p>
                           
                                       
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="signin-banner">
                        <div className="banner-content">
                            <img src="../../media/myai-logo-black.png" alt="logo" style={{ width: '300px', height: 'auto' }} /> 
                            <br />
                            <br />
                            <div className="banner-text">
                                <h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
                                    Your AI Assistant<br />
                                    to make you more Productive!
                                </h1>
                            </div>         
                        </div>
                    </div>
                </section>
            </div>
  );
};

export default PrivacyPage;
