
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/core.css';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/auth.service';
import { IUserDetailsResponse } from '../@common/interfaces/auth.interfaces';
import { apiService } from '../services/api.service';


const UserDashboard: React.FC = () => {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<IUserDetailsResponse>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        subscriptionType: '',
        subscriptionTypeText: '',
        userDetailsLink: '',
        subscriptionRenewDate: '',
        dailyQuota: '',
        isSubscriptionEnding: false,
      }
	);
    useEffect(() => {
        let token = getToken();
        getUser();
        // Add the token to the DOM as a hidden element
        let myaiTokenField = document.getElementById('myai_token');
        
        if (!myaiTokenField) {
            myaiTokenField = document.createElement('div');
            myaiTokenField.id = 'myai_token';
            myaiTokenField.style.display = 'none';
            myaiTokenField.textContent = token || '';
            document.body.appendChild(myaiTokenField);
        } else {
            
        }
        
    }, []);

const getUser = async () => {
    let user = await authService.checkAuth(navigate)
    if (user!=null){
        setUserDetails(user);
    }
   
}

const handleClickCustomerPortal = () => {
  
  const checkoutResponse = apiService.customerPortal();
  checkoutResponse.then((data) => {
      if (data != null) {
          window.location.href = data.customerPortalSessionUrl;
      }
  });
};
        //Get token, if the token is empty route back to login page
        const getToken = () => {
            let token = authService.getSession();
            if (!token) {
                navigate("/login");
            }
            return token;
          };


    return (
        <div id="main_content">
      <header className="site-header header-five header_trans-fixed" data-top="992">
        <div className="container">
          <div className="header-inner">
            <div className="toggle-menu">
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>

            <div className="site-mobile-logo">
              <a href="/user-dashboard" className="logo">
                <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
              </a>
            </div>

            <nav className="site-nav">
              <div className="close-menu">
                <span>Close</span>
                <i className="ei ei-icon_close"></i>
              </div>

              <div className="site-logo">
                <a href="index.html" className="logo">
                  <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                </a>
              </div>

              <div className="menu-wrapper" data-top="992">
                <ul className="site-main-menu">
                  <li><div> </div></li>
                </ul>

                <div className="nav-right">
                  <a href="signup.html" className="banner-btn">Go to the Chrome Extension</a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>

      <section className="about-two">
        <div className="container" style={{ marginTop: '150px' }}>
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="about-thumb wow pixFadeRight" data-wow-delay="0.6s">
                <img src="../../media/mySubscriptions.png" alt="My subscriptions image" />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-content-two">
                <div className="section-title" style={{ marginBottom: '60px' }}>
                  <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                    My Subscription
                  </h2>
                </div>
                <p className="description wow pixFadeUp" data-wow-delay="0.5s">
                  <b>Name</b> : {userDetails.firstName} <br />
                  <b>Registered Email</b> : {userDetails.email} <br />
                  <b>Subscription Type</b> :  {userDetails.subscriptionTypeText} <br />

                  {userDetails.subscriptionType === 'FREE' ? (
                    <div>
                        
                      <b style={{ color: 'red' }}>Quota used for today</b> :  <span style={{ color: 'red' }}>{userDetails.dailyQuota} </span><br />
                      
                    </div>
                ) : (
                    <div>
                        <b>Subscription valid till</b> :  {userDetails.subscriptionRenewDate} <br />    
                         <b>Quota used for today</b> :  {userDetails.dailyQuota} <br />
                    
                    </div>
                )}      
                 
                </p>
                {userDetails.subscriptionType === 'FREE' ? (
                  <a href="/pricing" className="banner-btn pix-btn-auth-pages wow fadeInUp" data-wow-delay="0.7s">Upgrade</a>
                ):(
                  <div>
                  <a href="#" className="banner-btn pix-btn-auth-pages wow fadeInUp right-margin"  data-wow-delay="0.7s"  onClick={(e) => {
                                            e.preventDefault(); // Prevent the default anchor behavior
                                            handleClickCustomerPortal();
                                        }}>Change Plan</a>
                  

                  {/* <a href="#" className="banner-btn-red pix-btn-red wow fadeInUp" data-wow-delay="0.7s"  onClick={(e) => {
                                            e.preventDefault(); // Prevent the default anchor behavior
                                            handleClickCustomerPortal();
                                        }}>Cancel Plan</a> */}
                  </div>
                
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
        
    );
};

export default UserDashboard;
