import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserDashboard from './components/userDashboard';
import LoginSuccess from './components/LoginSuccess';
import Register from './components/Register';
import Pricing from './components/Pricing';
import Login from './components/Login';
import RegistrationComplete from './components/RegistrationComplete';
import PrivacyPage from './components/Privacy';
import ForgotPassword from './components/ForgotPassword';
import ResetPasswordEmailSent from './components/reset-password-email-sent';
import ForgotPasswordLogin from './components/ForgotPasswordLogin';
import GoogleRedirect from "./components/google-redirect";

function App() {
  return (
    <Router >
    <div className="App">
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/user-dashboard" element={<UserDashboard />} />
        <Route path="/register" element={<Register />} /> 
        <Route path="/pricing" element={<Pricing />} /> 
        <Route path="/registration-success" element={<RegistrationComplete />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password-email" element={<ResetPasswordEmailSent />} />
        <Route path="/forgot-password-login" element={<ForgotPasswordLogin />} />
        <Route path="/auth/google-redirect" element={< GoogleRedirect/>} />
        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
