import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import { authService } from '../services/auth.service';

function APICallOnLoad() {
	const [data, setData] = useState<any>(null);
	const location = useLocation();
    const navigate = useNavigate();

    const [error, setError] = useState<string>('');


	useEffect(() => {
		const fetchData = async () => {
			try {
				const queryParams = new URLSearchParams(location.search);
				const code = queryParams.get("code");
				const scope = queryParams.get("scope");
				const authuser = queryParams.get("authuser");
				const prompt = queryParams.get("prompt");

				// Define your API endpoint
				const apiUrl = `${process.env.REACT_APP_API_URL}/auth/google/redirect`;
				const response = await axios.get(apiUrl, {
					params: {
						code,
						scope,
						authuser,
						prompt,
					},
				});
				authService.setSession(response.data.token.accessToken);
                window.location.href = '/login-success';
			} catch (errorRes: any) {
				// Handle errors, e.g., network issues or invalid response
				console.error("Error fetching data:", errorRes.response);
				const state = { message: errorRes.response.data.message };
				setError("Error in login via Google");
                navigate("/login", { state });
			}
		};

		fetchData(); // Immediately invoke the async function
	}, []);

	return (
		<div>
			{data ? (
				<div>
					{/* Render the API response data */}
					<h1>Data from API:</h1>
					<pre>{JSON.stringify(data, null, 2)}</pre>
				</div>
			) : (
				<p>Loading data...</p>
			)}
		</div>
	);
}

export default APICallOnLoad;
