import React, { FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import '../assets/css/core.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../services/auth.service';
import { apiService } from '../services/api.service';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter a valid email address.")
        .required("Please enter your email address."),
    firstname: yup.string().required("Please provide your first name."),
    lastname: yup.string().required("Please provide your last name."),
    password: yup.string()
    .required("Please enter your password.")
    .matches(/^.{6,}$/, "Password must be at least 6 characters long."),
    confirmPassword: yup
		.string()
		.oneOf([yup.ref("password")], "Passwords must match."),
});



const Login: React.FC = () => {
    const location = useLocation();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [validationErrors, setValidationErrors] = useState<{ email?: string; password?: string; firstname?: string;lastname?: string; confirmPassword?:string }>({});
    const navigate = useNavigate();
    
  
    useEffect(() => {
        if (authService.getSession() !== null) {
            navigate("/user-dashboard");
        }

        if (location.state != undefined && location.state.message != undefined) {
            setError(location.state.message);
        }
    }, []);

    const onSubmitGoogle = () => {
		window.location.href =
			process.env.REACT_APP_API_URL + "/auth/google/authenticate";
	};

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            await schema.validate({ email, password,firstname,lastname,confirmPassword }, { abortEarly: false });
            const response = await apiService.register(firstname,lastname, email, password);

            if (response.ok){
                window.location.href = '/registration-success';
            } else {
                if (response.status === 409) {
                    setError(
                         "Email address is already registered, try to login or use the forget password option"
                     );
                     return;
                } else {
                    setError(
                        "Error in connecting to the backend, please try later"
                    );
                }
            }
 
            
           // 
        } catch (validationError: any) {
            if (validationError.inner) {
                const errors: { email?: string; password?: string; firstname?: string;lastname?: string; confirmPassword?:string } = {};
                validationError.inner.forEach((err: any) => {
                    errors[err.path as keyof typeof errors] = err.message;
                });
                setValidationErrors(errors);
            } else {
                setError("Error in connecting to the backend, please try later");
            }
            
            
        }
    };


    
    return (
        <div id="home-version-1" className="home-version-1" data-style="default">
            <a href="#main_content" data-type="section-switch" className="return-to-top">
                <i className="fa fa-chevron-up"></i>
            </a>

            <div id="main_content">
                <header className="site-header header-two header_trans-fixed" data-top="992">
                    <div className="container">
                        <div className="header-inner">
                            <div className="site-mobile-logo">
                                <a href="/login" className="logo">
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                </a>
                            </div>
                            <div className="toggle-menu">
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                            <nav className="site-nav nav-two">
                                <div className="close-menu">
                                    <span>Close</span>
                                    <i className="ei ei-icon_close"></i>
                                </div>
                                <div className="site-logo">
                                    <a href="/login" className="logo">
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                    </a>
                                </div>
                                <div className="menu-wrapper" data-top="992">
                                    <ul className="site-main-menu">
                                        <li></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className="signin">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="signin-from-wrapper">
                                    <div className="signin-from-inner">
                                        <h2 className="title">Register</h2>
                                        <p></p>
                                        <p>
                                            Register now to access My AI Assistant, Its Free!
                                        </p>
                                        <br></br>
                                        {error && (
                                            <Alert severity="error" style={{ marginBottom: '5px' }}>
                                                {error}
                                            </Alert>
                                        )}
                                        <form onSubmit={handleSubmit} className="input-form">
                                            <TextField 
                                                id="email" 
                                                label="Email" 
                                                type="email" 
                                                variant="outlined" 
                                                fullWidth 
                                                margin="dense"
                                                value={email} 
                                                onChange={(e) => setEmail(e.target.value)}
                                                error={!!validationErrors.email}
                                                helperText={validationErrors.email}
                                            />
                                            <TextField 
                                                id="firstname" 
                                                label="First Name" 
                                                type="text" 
                                                variant="outlined" 
                                                fullWidth 
                                                margin="dense"
                                                value={firstname} 
                                                onChange={(e) => setFirstname(e.target.value)}
                                                error={!!validationErrors.firstname}
                                                helperText={validationErrors.firstname}
                                            />

<TextField 
                                                id="lastname" 
                                                label="Last Name" 
                                                type="text" 
                                                variant="outlined" 
                                                fullWidth 
                                                margin="dense"
                                                value={lastname} 
                                                onChange={(e) => setLastname(e.target.value)}
                                                error={!!validationErrors.lastname}
                                                helperText={validationErrors.lastname}
                                            />

                                            <TextField 
                                                id="password" 
                                                label="Password" 
                                                type="password" 
                                                fullWidth
                                                variant="outlined" 
                                                margin="dense"
                                                value={password} 
                                                onChange={(e) => setPassword(e.target.value)}
                                                error={!!validationErrors.password}
                                                helperText={validationErrors.password}
                                            />
                                            <TextField 
                                                id="confirmPassword" 
                                                label="Retype Password" 
                                                type="password" 
                                                fullWidth
                                                variant="outlined" 
                                                margin="dense"
                                                value={confirmPassword} 
                                                error={!!validationErrors.confirmPassword}
                                                helperText={validationErrors.confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                             
                                            />
                                           
                                            <button type="submit" className="pix-btn-auth-pages">Register</button>
                                            <button type="button" className="pix-btn-auth-google"
								onClick={onSubmitGoogle}
							>
								<img
										src="../media/google-icon.png"
										alt="logo"
                                        style={{ height: "25px", marginRight: "5px" }}
									/>Login with Google
							</button>
                                            <p>Do you have an account? <a href="/login">Login</a> now.</p>
                                        </form>
                                    </div>
                                    <ul className="animate-ball">
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="signin-banner">
                        <div className="banner-content">
                            <img src="../../media/myai-logo-black.png" alt="logo" style={{ width: '300px', height: 'auto' }} /> 
                            <br />
                            <br />
                            <div className="banner-text">
                                <h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
                                    Your AI Assistant<br />
                                    to make you more Productive!
                                </h1>
                            </div>         
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Login;
