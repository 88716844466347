import React, { FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import '../assets/css/core.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../services/auth.service';
import { apiService } from '../services/api.service';
import * as yup from "yup";
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter a valid email address.")
        .required("Please enter your email address."),
    password: yup.string()
    .required("Please enter your password.")
    .matches(/^.{6,}$/, "Password must be at least 6 characters long."),
    confirmPassword: yup
		.string()
		.oneOf([yup.ref("password")], "Passwords must match."),
});

const boxStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	borderRadius: "10px",
};

const ForgotPasswordLogin: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code") ?? "";
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [validationErrors, setValidationErrors] = useState<{ email?: string; password?: string; confirmPassword?:string }>({});
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const emailFromQuery = queryParams.get("email") ?? "";

	const handleModalClose = () => {
		setModalOpen(false);
		navigate("/login");
	};
    
    useEffect(() => {
        if (authService.getSession() !== null) {
            navigate("/user-dashboard");
        }

        if (location.state != undefined && location.state.message != undefined) {
            setError(location.state.message);
        }

        if (emailFromQuery) {
            setEmail(emailFromQuery);
        }
       
    }, [emailFromQuery, navigate, location.state]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await schema.validate({ email, password,confirmPassword }, { abortEarly: false });
            const response = await apiService.forgotPasswordUpdate(email,password,code);

            if (response.ok){
                setModalOpen(true);
            } else {
                if (response.status === 403) {
                    setError(
                         "Password reset link has expired or the email address in incorrect"
                     );
                     return;
                } else {
                    setError(
                        "Error in connecting to the backend, please try later"
                    );
                }
            }
 
            
           // 
        } catch (validationError: any) {
            if (validationError.inner) {
                const errors: { email?: string; password?: string; confirmPassword?:string } = {};
                validationError.inner.forEach((err: any) => {
                    errors[err.path as keyof typeof errors] = err.message;
                });
                setValidationErrors(errors);
            } else {
                setError("Error in connecting to the backend, please try later");
            }
            
            
        }
    };


    
    return (
        <div id="home-version-1" className="home-version-1" data-style="default">
            <a href="#main_content" data-type="section-switch" className="return-to-top">
                <i className="fa fa-chevron-up"></i>
            </a>

            <div id="main_content">
                <header className="site-header header-two header_trans-fixed" data-top="992">
                    <div className="container">
                        <div className="header-inner">
                            <div className="site-mobile-logo">
                                <a href="/login" className="logo">
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="sticky-logo" />
                                </a>
                            </div>
                            <div className="toggle-menu">
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                            <nav className="site-nav nav-two">
                                <div className="close-menu">
                                    <span>Close</span>
                                    <i className="ei ei-icon_close"></i>
                                </div>
                                <div className="site-logo">
                                    <a href="/login" className="logo">
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="sticky-logo" />
                                    </a>
                                </div>
                                <div className="menu-wrapper" data-top="992">
                                    <ul className="site-main-menu">
                                        <li></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className="signin">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="signin-from-wrapper">
                                    <div className="signin-from-inner">
                                        <h2 className="title">Reset your password</h2>
                                        <p></p>
                                        <p>
                                            Please enter your email and the new password below.
                                        </p>
                                        <br></br>
                                        {error && (
                                            <Alert severity="error" style={{ marginBottom: '5px' }}>
                                                {error}
                                            </Alert>
                                        )}
                                        <form onSubmit={handleSubmit} className="input-form">
                                            <TextField 
                                                id="email" 
                                                label="Email" 
                                                type="email" 
                                                variant="outlined" 
                                                fullWidth 
                                                margin="dense"
                                                value={email} 
                                                onChange={(e) => setEmail(e.target.value)}
                                                error={!!validationErrors.email}
                                                helperText={validationErrors.email}
                                            />

                                            <TextField 
                                                id="password" 
                                                label="New password" 
                                                type="password" 
                                                fullWidth
                                                variant="outlined" 
                                                margin="dense"
                                                value={password} 
                                                onChange={(e) => setPassword(e.target.value)}
                                                error={!!validationErrors.password}
                                                helperText={validationErrors.password}
                                            />
                                            <TextField 
                                                id="confirmPassword" 
                                                label="Retype new password" 
                                                type="password" 
                                                fullWidth
                                                variant="outlined" 
                                                margin="dense"
                                                value={confirmPassword} 
                                                error={!!validationErrors.confirmPassword}
                                                helperText={validationErrors.confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                             
                                            />
                                           
                                            <button type="submit" className="pix-btn-auth-pages">Reset the password</button>
                                        </form>
                                    </div>
                                    <ul className="animate-ball">
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="signin-banner">
                        <div className="banner-content">
                            <img src="../../media/myai-logo-black.png" alt="logo" style={{ width: '300px', height: 'auto' }} /> 
                            <br />
                            <br />
                            <div className="banner-text">
                                <h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
                                    Your AI Assistant<br />
                                    to make you more Productive!
                                </h1>
                            </div>         
                        </div>
                    </div>
                </section>
            </div>
            <Modal
				open={modalOpen}
				onClose={handleModalClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={modalOpen}>
					<Box sx={boxStyle}>
						<>
							<Typography id="transition-modal-description" sx={{ mt: 2 }}>
								Your password has been reset successfully. Please login again.
								<br></br><br></br>
							</Typography>

							<div className="flex justify-center items-center gap-10">
								<Button
									variant="outlined"
									aria-label="Update"
									color="secondary"
									size="medium"
									className=" mt-16 mr-4"
									type="button"
									style={{ width: "300px" }}
									onClick={() => handleModalClose()}
								>
									Take me to the Login Page
								</Button>
							</div>
						</>
					</Box>
				</Fade>
			</Modal>   
        </div>
    );
};

export default ForgotPasswordLogin;
