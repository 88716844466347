import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import {
  IAuthTokenData,
  IUserDetailsResponse,
} from "./../@common/interfaces/auth.interfaces";
import { apiService } from "./api.service";
import { useNavigate } from "react-router-dom";

export class AuthService {
  async decodeToken(token: string): Promise<IAuthTokenData> {
    const tokenPayload: IAuthTokenData = await jwtDecode(token);
    return tokenPayload;
  }

  async setSession(token: string) {
    const tokenPayload = await this.decodeToken(token);
    const session = {
      uid: tokenPayload.userId,
      role: tokenPayload.role,
      token: token,
      firstName: tokenPayload.firstName,
      lastName: tokenPayload.lastName,
      subscriptionType: tokenPayload.subscriptionType,
      renewalDate: tokenPayload.renewalDate,
    };
    Cookies.set("session", JSON.stringify(session), { expires: 730 });
  }

  getSession(): any {
    const sessionString = Cookies.get("session");
    if (sessionString != null) {
      const session = JSON.parse(sessionString);
      return session.token;
    }
    return null;
  }

  deleteSession() {
    Cookies.remove("session");
  }

  async checkAuth(
    navigate: (path: string, state?: any) => void
  ): Promise<IUserDetailsResponse | null> {
    let sessionCookie = this.getSession();
    if (sessionCookie == undefined || sessionCookie == null) {
      navigate("/login", { state: { message: "User session expired" } });
      return null;
    } else {
      const user = await apiService.authMe(sessionCookie);
      if (user == undefined || user == null) {
        authService.deleteSession();
        navigate("/login", { state: "User session expired" });
      }
      return user;
    }
  }
}

export const authService = new AuthService();
