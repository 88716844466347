
import React, { useEffect,useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/core.css';
import 'elegant-icons/style.css';
import { apiService } from '../services/api.service';
import { authService } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { IUserDetailsResponse } from '../@common/interfaces/auth.interfaces';

//import "../assets/css/app.css"

const Pricing: React.FC = () => {
    const [isAnnual, setAnnual] = useState(true);
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<IUserDetailsResponse>({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        subscriptionType: '',
        subscriptionTypeText: '',
        userDetailsLink: '',
        subscriptionRenewDate: '',
        dailyQuota: '',
        isSubscriptionEnding : false
      });


  const handleToggle = () => {
    setAnnual(!isAnnual);
  };
    useEffect(() => {
        checkAuth();
    }, []);


        

    const checkAuth = async () => {
        try {
            let user = await authService.checkAuth(navigate);
            if (user!=null){
                setUserDetails(user);
                //if user is not a free user route the user to the dashboard
                if (user.subscriptionType != 'FREE'){
                    navigate("/user-dashboard");
                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    const handleClick = (subscriptionType: string) => {
        let priceId="";
        if (subscriptionType == 'GOLD') {
            if (isAnnual){
                priceId=process.env.REACT_APP_STRIPE_GOLD_ANNUAL || "";         
            } else {
                priceId=process.env.REACT_APP_STRIPE_GOLD_MONTHLY || "";
            }
        } else {
            if (isAnnual){
                priceId=process.env.REACT_APP_STRIPE_PLATINUM_ANNUAL || "";
            } else {
                priceId=process.env.REACT_APP_STRIPE_PLATINUM_MONTHLY || "";
            }
        }
        const checkoutResponse = apiService.checkout(priceId);
        checkoutResponse.then((data) => {
            if (data != null) {
                console.log (data);
                window.location.href = data.checkoutUrl;
            }
        });
    };

    

    return (
        
        <div id="main_content">
            <section className="pricing-two-single">
                <div className="container">
                    <div className="section-title text-center">
                        <h3 className="sub-title wow pixFadeUp">Subscription Plans</h3>
                        <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                            What’s your preferred plan?<br />
                            
                        </h2>
                    </div>
                    <nav className={`pricing-tab wow pixFadeUp  ${isAnnual ? 'annual' : ''}`} data-wow-delay="0.4s">
                        <span className="monthly_tab_title tab-btn " >
                            Monthly
                        </span>
                        <span id="slider" className={`pricing-tab-switcher ${isAnnual ? 'active' : ''}`} onClick={handleToggle}></span>
                        <span className="annual_tab_title tab-btn active">
                            Annual
                        </span>
                    </nav>
                    
                    <div className="row advanced-pricing-table">
                        <div className="col-lg-4">
                            <div className="pricing-table color-free style-two wow  disabled-div" data-wow-delay="0.5s">
                                <div className={`pricing-header pricing-amount ${isAnnual ? 'change-subs-duration' : ''}`} >
                                    <div className="annual_price">
                                        <h2 className="price">$0.00/Month</h2> <div className="smallText">Free to use</div>
                                    </div>
                                    <div className="monthly_price">
                                        <h2 className="price">$0.00/Month</h2>
                                    </div>
                                    <h3 className="price-title">Basic Plan</h3>
                                    <p>Free use of our AI services with a limited quota</p>
                                </div>
                                <ul className="price-feture">
                                    <li className="have">5 requests a day</li>
                                    <li className="have">Write, Create, 'Make it better' and Correct text with AI </li>
                                <li className="have">Summarize, Create Mindmaps and Explain text with AI </li>
                                </ul>
                                <div className="action text-left ">
                                    <div className="pix-btn btn-outline">
                                    Current Plan
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="pricing-table color-two style-two featured wow pixFadeLeft" data-wow-delay="0.7s">
                                <div className="trend">
                                    <p>Popular</p>
                                </div>
                                <div className={`pricing-header pricing-amount ${isAnnual ? 'change-subs-duration' : ''}`}  >
                                    <div className="annual_price">
                                        <h2 className="price">$6.92/Month </h2> <div className="smallText">$83 Billed annually</div>
                                    </div>
                                    <div className="monthly_price">
                                        <h2 className="price">$8.98/Month</h2>
                                    </div>
                                    <h3 className="price-title">Gold Plan</h3>
                                    <p>Flexibility to use our AI services for most of your tasks  </p>
                                </div>
                                <ul className="price-feture">
                                <li className="have">50 requests a day</li>
                                <li className="have">Write, Create, 'Make it better' and Correct text with AI </li>
                                <li className="have">Summarize, Create Mindmaps and Explain text with AI </li>
                                </ul>
                                <div className="action text-left">
                                <a href="#" className="pix-btn btn-outline" onClick={(e) => {
                                            e.preventDefault(); // Prevent the default anchor behavior
                                            handleClick('GOLD');
                                        }}>Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="pricing-table color-three style-two wow pixFadeLeft" data-wow-delay="0.9s">
                                <div className={`pricing-header pricing-amount ${isAnnual ? 'change-subs-duration' : ''}`} >
                                    <div className="annual_price">
                                    <h2 className="price">$9.92/Month</h2> <div className="smallText">$119 Billed annually</div>
                                    </div>
                                    <div className="monthly_price">
                                        <h2 className="price">$11.98/Month</h2>
                                    </div>
                                    <h3 className="price-title">Premium Account</h3>
                                    <p>Freedom to use our AI services to all types of tasks  </p>
                                </div>
                                <ul className="price-feture">
                                <li className="have">100 requests a day</li>
                                <li className="have">Write, Create, 'Make it better' and Correct text with AI </li>
                                <li className="have">Summarize, Create Mindmaps and Explain text with AI </li>
                                </ul>
                                <div className="action text-left">
                                    <a href="#" className="pix-btn btn-outline" onClick={(e) => {
                                            e.preventDefault(); // Prevent the default anchor behavior
                                            handleClick('PLATINUM');
                                        }}>Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Pricing;
