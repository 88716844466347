import React,{useEffect} from 'react';
import 'boxicons/css/boxicons.min.css';
import '../style.css'; 
import { apiService } from '../services/api.service';
import { authService } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';


    const LoginSuccess = () => {
    const navigate = useNavigate();
    
    //Get token, if the token is empty route back to login page
    const getToken = () => {
        let token = authService.getSession();
        if (!token) {
            navigate("/login");
        }
        return token;
      };

    useEffect(() => {
        let token = getToken();
        authService.checkAuth(navigate)
        // Add the token to the DOM as a hidden element
        let myaiTokenField = document.getElementById('myai_token');
        
        if (!myaiTokenField) {
            myaiTokenField = document.createElement('div');
            myaiTokenField.id = 'myai_token';
            myaiTokenField.style.display = 'none';
            myaiTokenField.textContent = token || '';
            document.body.appendChild(myaiTokenField);
        } else {
            
        }

        const timeoutId = setTimeout(() => {
            navigate('/user-dashboard'); 
        }, 300);

        // Clean up the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    const validateSession = () => {
        const token = getToken();
        
        if (!token) {
            window.location.href = '/login';
        }
    }

    
    return (
        <section className="container forms">
            <div className="form success">
                <div className="form-content">
                    <header>Login Successful</header>
                    <div className="success-message">
                        <p>Redirecting the dashboard page</p>
                    </div>
                    <div className="field button-field">
                        <button onClick={() => window.location.href = '/user-dashboard'}>
                            Go to Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginSuccess;
