import React, { FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import '../assets/css/core.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../services/auth.service';
import { apiService } from '../services/api.service';
import * as yup from "yup";

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter a valid email address.")
        .required("Please enter your email address."),
    
});

const defaultValues = {
    email: "",
};



const ForgotPassword: React.FC = () => {
    const location = useLocation();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [validationErrors, setValidationErrors] = useState<{ email?: string; }>({});
    const navigate = useNavigate();
    
    
    useEffect(() => {
        if (authService.getSession() !== null) {
            navigate("/user-dashboard");
        }
        
        if (location.state && location.state.message) {
            setError(location.state.message);
        }
    }, [location, navigate]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            await schema.validate({ email }, { abortEarly: false });
            const data = await apiService.forgotPassword(email);
            window.location.href = '/reset-password-email';
        } catch (validationError: any) {
            if (validationError.inner) {
                const errors: { email?: string; password?: string } = {};
                validationError.inner.forEach((err: any) => {
                    errors[err.path as keyof typeof errors] = err.message;
                });
                setValidationErrors(errors);
            } else {
                setError("Email or Password is incorrect");
            }
        }
    };

    return (
        <div id="home-version-1" className="home-version-1" data-style="default">
            <a href="#main_content" data-type="section-switch" className="return-to-top">
                <i className="fa fa-chevron-up"></i>
            </a>

            <div id="main_content">
                <header className="site-header header-two header_trans-fixed" data-top="992">
                    <div className="container">
                        <div className="header-inner">
                            <div className="site-mobile-logo">
                                <a href="/login" className="logo">
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="sticky-logo" />
                                </a>
                            </div>
                            <div className="toggle-menu">
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                            <nav className="site-nav nav-two">
                                <div className="close-menu">
                                    <span>Close</span>
                                    <i className="ei ei-icon_close"></i>
                                </div>
                                <div className="site-logo">
                                    <a href="/login" className="logo">
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="sticky-logo" />
                                    </a>
                                </div>
                                <div className="menu-wrapper" data-top="992">
                                    <ul className="site-main-menu">
                                        <li></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className="signin">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="signin-from-wrapper">
                                    <div className="signin-from-inner">
                                        <h2 className="title">Reset Forgotten Password</h2>
                                        <p></p>
                                        <p>
                                            Please enter your email address below.<br></br> If you have an account with us, we will send you an email with a link to reset your password.
                                        </p>
                                        {error && (
                                            <Alert severity="error" style={{ marginBottom: '5px' }}>
                                                {error}
                                            </Alert>
                                        )}
                                        <form onSubmit={handleSubmit} className="input-form">
                                            <TextField 
                                                id="email" 
                                                label="Email" 
                                                type="email" 
                                                variant="outlined" 
                                                fullWidth 
                                                margin="dense"
                                                value={email} 
                                                onChange={(e) => setEmail(e.target.value)}
                                                error={!!validationErrors.email}
                                                helperText={validationErrors.email}
                                            />
                                           
                                           
                                            <button type="submit" className="pix-btn-auth-pages">Reset Password</button>
                                            <p>Don’t have an account? <a href="/Register">Register</a> now.</p>
                                        </form>
                                    </div>
                                    <ul className="animate-ball">
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="signin-banner">
                        <div className="banner-content">
                            <img src="../../media/myai-logo-black.png" alt="logo" style={{ width: '300px', height: 'auto' }} /> 
                            <br />
                            <br />
                            <div className="banner-text">
                                <h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
                                    Your AI Assistant<br />
                                    to make you more Productive!
                                </h1>
                            </div>         
                        </div>
                    </div>
                </section>
            </div>
             
        </div>
        
        
    );
};

export default ForgotPassword;
