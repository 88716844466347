import React from 'react';
import '../assets/css/core.css';
import { useNavigate } from 'react-router-dom';

const RegistrationComplete: React.FC = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/login");
    };

    return (
        <div id="home-version-1" className="home-version-1" data-style="default">
            <a href="#main_content" data-type="section-switch" className="return-to-top">
                <i className="fa fa-chevron-up"></i>
            </a>

            <div id="main_content">
                <header className="site-header header-two header_trans-fixed" data-top="992">
                    <div className="container">
                        <div className="header-inner">
                            <div className="site-mobile-logo">
                                <a href="/login" className="logo">
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                    <img src="../../media/myai-logo.jpg" alt="site logo" className="sticky-logo" />
                                </a>
                            </div>
                            <div className="toggle-menu">
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                            <nav className="site-nav nav-two">
                                <div className="close-menu">
                                    <span>Close</span>
                                    <i className="ei ei-icon_close"></i>
                                </div>
                                <div className="site-logo">
                                    <a href="/login" className="logo">
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="main-logo" />
                                        <img src="../../media/myai-logo.jpg" alt="site logo" className="sticky-logo" />
                                    </a>
                                </div>
                                <div className="menu-wrapper" data-top="992">
                                    <ul className="site-main-menu">
                                        <li></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

                <section className="signin">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="signin-from-wrapper">
                                    <div className="signin-from-inner">
                                        <h2 className="title">Sign Up Complete</h2>
                                        <p></p>
                                        <p>
                                            Your registration is complete! Please log in to continue.
                                        </p>
                                        <br />
                                        <button onClick={handleLogin} className="pix-btn-auth-pages">Log In</button>
                                    </div>
                                    <ul className="animate-ball">
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                        <li className="ball"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="signin-banner">
                        <div className="banner-content">
                            <img src="../../media/myai-logo-black.png" alt="logo" style={{ width: '300px', height: 'auto' }} />
                            <br />
                            <br />
                            <div className="banner-text">
                                <h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
                                    Your AI Assistant<br />
                                    to make you more Productive!
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default RegistrationComplete;
